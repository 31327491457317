import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../utils/mergeEdges'
import { Container } from '../shared/Grid'
import { Flex } from '../shared/Helpers'
import getSocialIcons from '../shared/Helpers/getSocialIcons'
import Link from '../shared/Link'
import * as T from '../shared/Typography'
import styled from '../styled'

interface ISocial {
  label: string
  src: string
}

interface IContact {
  socialSection: {
    title: string
    description: string
  }
}

interface IFooter {
  social: {
    menu: ISocial[]
  }
}

const CardBox = styled.div`
  text-align: center;
  background-color: ${({ theme: { colors } }) => colors.grayscale.lighter};
  border-radius: 4px;
  padding: 4em 2em;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints[0]}) {
    padding: 2em 1em;
  }
`

const SocialLink = styled(Link).attrs({ ghost: true })`
  margin-right: 1em;
  color: ${({ theme: { colors } }) => colors.grayscale.default};
  transition: color ${({ theme: { transition } }) => transition.ease()};

  &:hover {
    color: ${({ theme: { colors } }) => colors.grayscale.light};
  }

  svg {
    width: 30px;
    height: auto;
  }
`

const socialRender: React.FC<ISocial> = ({ label, src }) => {
  if (!label || !src) {
    return null
  }

  return (
    <SocialLink key={label} to={src} aria-label={label}>
      {getSocialIcons(label)}
    </SocialLink>
  )
}

const ContactSocial: React.FC = () => {
  const { allFoundationYaml, allContentYaml } = useStaticQuery(graphql`
    query SocialContact {
      allContentYaml {
        edges {
          node {
            footer {
              social {
                menu {
                  label
                  src
                }
              }
            }
          }
        }
      }
      allFoundationYaml {
        edges {
          node {
            contact {
              socialSection {
                title
                description
              }
            }
          }
        }
      }
    }
  `)

  const dataFoundationYaml = mergeEdges(allFoundationYaml)
  const { contact }: { contact?: IContact | null } = dataFoundationYaml!

  const dataContentYaml = mergeEdges(allContentYaml)
  const { footer }: { footer?: IFooter | null } = dataContentYaml!

  if (!contact || !footer) {
    return null
  }

  return (
    <Container mb="6em" id="social-media">
      <CardBox>
        <T.SubTitle ml=".7em" mb="1rem">
          {contact.socialSection.title}
        </T.SubTitle>
        <T.Description textAlign="center" mb="1rem" color="greyMedium">
          {contact.socialSection.description}
        </T.Description>
        <Flex mt="1.5em" width="100%" justifyContent="center">
          <Flex flexWrap="wrap" justifyContent="center">
            {footer.social.menu.map(socialRender)}
          </Flex>
        </Flex>
      </CardBox>
    </Container>
  )
}

export default ContactSocial
