import * as React from 'react'

import Card from '../shared/Card'
import { Container } from '../shared/Grid'
import { Box, Flex } from '../shared/Helpers'
import Link from '../shared/Link'
import { Caption, Text } from '../shared/Typography'
import { IContent, IWays } from './types'

const chatPath = require('../../images/icons/chat.svg')
const smilePath = require('../../images/icons/smile.svg')
const editPath = require('../../images/icons/edit.svg')

interface IProps {
  data: IWays
}

interface ITemplate {
  data: IContent
  icon: string
}

const Template: React.FC<ITemplate> = ({
  data: { title, description, cta, ctaLink },
  icon,
}) => {
  const width = ['100%', 'calc(100% / 3 - .7em)']

  return (
    <Box width={width} mb="1em">
      <Card>
        <Flex>
          <div>
            <img src={icon} alt={title} />
          </div>
          <Caption ml=".7em" mb="1rem">
            {title}
          </Caption>
        </Flex>

        <Text mb="1.5rem" color="grey">
          {description}
        </Text>

        <Link color="blue" to={ctaLink} withIcon="arrow">
          {cta}
        </Link>
      </Card>
    </Box>
  )
}

const ContactWays: React.FC<IProps> = ({
  data: { getInTouch, media, support },
}) => {
  return (
    <Container>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        mt={['4em']}
        mb={['4em', '7.5em']}
      >
        <Template data={getInTouch} icon={chatPath} />
        <Template data={media} icon={smilePath} />
        <Template data={support} icon={editPath} />
      </Flex>
    </Container>
  )
}

export default ContactWays
