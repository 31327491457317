import * as React from 'react'

import Hero from '../components/Contact/Hero'
import ContactSocial from '../components/ContactSocial'
import ContactWays from '../components/ContactWays'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'
import Layout from '../layouts'

const ContactPage: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <ContactWays />
      <ContactSocial />
      <Newsletter />
    </Layout>
  )
}

export default ContactPage
